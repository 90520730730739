<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center content-wrapper" id="page-login">
    <div class="vx-col w-11/12 sm:w-3/5 md:w-1/2 lg:w-3/4 xl:w-3/5">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
           <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col w-full py-8 px-12 lg:p-0 lg:w-1/2 text-center">
              <img v-if="appName ==='FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="65%" height="auto" alt="Flippay" class="mx-auto"/>
              <img v-else-if="appName==='RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="65%" height="auto" alt="Rellopay" class="mx-auto"/>
              <div class="vx-card__title text-center pt-6 lg:hidden">
                <h3 class="mb-4 text-xl lg:text-2xl">Recover your password</h3>
                <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
              </div>
            </div>
            <div class="vx-col w-full lg:w-1/2">
              <div class="pt-2 pb-4 sm:pb-6 px-6 sm:px-10 lg:pt-10 lg:pb-12 lg:px-10 login-tabs-container bg-white">
                <div class="vx-card__title mb-12 lg:mb-4 text-center lg:text-left hidden lg:block">
                  <h3 class="mb-4 text-xl lg:text-2xl text-center">Recover your password</h3>
                  <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
                </div>

                <div class="mb-8 pt-4">
                  <label class="w-full">Email</label>
                  <vs-input v-validate="'required|email|min:3'" data-vv-validate-on="blur" name="email" v-model="user.email" class="w-full mb-1 r-0"/>
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                </div>
                
               <div class="flex flex-col">
                 <vs-button size="medium" v-round @click="goToLogin" class="round w-full mb-4">Back To Login</vs-button>
                 <vs-button size="medium" v-round @click="recoverPassword" class="w-full round">Recover Password</vs-button>
               </div>               
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    data() {
      return {
        user: {
          email: '',
        },
        showDismissibleAlert: false,
        message: '',
        loginError: '',
        appName: process.env.VUE_APP_NAME ||"FlipPay"
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email != '';
      },
    },
    methods: {
      ...mapActions('auth', ['forgotPwd']),
      goToLogin(){
        this.$router.push('/login')
      },
      recoverPassword() {
        const payload = {
          userDetail: this.user
        }
        this.$validator.validateAll().then( result => {
          if(result) {
            this.$vs.loading();
            this.forgotPwd(payload)
              .then((response) => {
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Message',
                  text: response.data.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'green'
                })
              })
              .catch((error) => {
                this.showErrorDismissibleAlert = true
                this.message = error.message
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Error',
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
              })
          }
        }).catch(error => {
           this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Error Message',
                  text: error,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
        })
      }
    }
  }
</script>